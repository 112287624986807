<template>
    <section class="mt-4">
      <b-pagination
        :total="total"
        :current="currentPage"
        :simple="false"
        :per-page="filters.limit"
        :paginated="false"
        :pagination-simple="true"
        order="is-right"
        @change="pageChange">
      </b-pagination>
      <div class="table-container">
        <b-table
          :data="data"
          :bordered="true"
          :narrowed="true"
          :hoverable="true"
          :focusable="true"
          default-sort-direction="desc"
          default-sort="date">

          <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
            <router-link :to="{name: 'a-user', params: {id: props.row.id}}">
              {{ props.row.id }}
            </router-link>
          </b-table-column>

          <b-table-column field="email" label="Email" centered v-slot="props">
            {{ props.row.email }}
          </b-table-column>

          <b-table-column field="date" label="Дата регистрации" centered v-slot="props">
            {{ props.row.date }}
          </b-table-column>

<!--           <b-table-column field="role" label="Роль" centered v-slot="props">
            <template v-if="props.row.role">
              <b-tag type="is-primary" >{{props.row.role.name}}</b-tag>
            </template>
          </b-table-column> -->

<!--           <b-table-column field="is_admin" label="Администратор" centered v-slot="props">
            <template v-if="props.row.is_admin == true">
              <font-awesome-icon icon="check" />
            </template>
            <template v-else="">
              <font-awesome-icon icon="times" />
            </template>
          </b-table-column> -->

          <b-table-column field="action" label="Действие" centered v-slot="props">
            <b-tooltip label="Редактирование">
              <router-link :to="{name: 'a-user', params: {id: props.row.id }}" class="navbar-item">
                <font-awesome-icon icon="pen" />
              </router-link>
            </b-tooltip>
            <b-tooltip label="Cбросить сессию">
              <a href="#" class="navbar-item" @click="resetSession(props.row.id )">
                <font-awesome-icon icon="times" />
              </a>
            </b-tooltip>
            <b-tooltip label="Изменить пароль">
              <router-link :to="{name: 'a-user-passwd', params: {id: props.row.id }}" class="navbar-item">
                <font-awesome-icon icon="key" />
              </router-link>
            </b-tooltip>
          </b-table-column>

        </b-table>
      </div>
    </section>
</template>


<script>
import { EventBus, limits } from '@/utils'
import { getUsers, getPageUsers, resetSessionUser } from '@/api/user'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: "UsersTable",
  data() {
    const data = []

    return {
      data: data,
      limits: limits,
      currentPage: 1,
      total: 0,
      filters: {
        order: 'asc',
        limit: 25
      },
      is_admin: false
    }
  },
  computed: {
    //
  },
  mounted: function() {
    let $this = this

    getUsers(this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;
        $this.total = data.total

        data.data.forEach((item) => {
          this.data.push(item)
        })
      })
      .catch(
        this.data = []
      )

    // EventBus.$on('error', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.error')} ${error}`,
    //     message: 'error',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

    // EventBus.$on('fail', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.fail')} ${error}`,
    //     message: 'fail',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

  },
  beforeDestroy: function(){
    EventBus.$off('error')
    EventBus.$off('fail')
  },
  methods: {
    pageChange(page){
      if(page){
        this.currentPage = page
      }
      getPageUsers(this.currentPage, this.filters, this.$store.state.jwt_user)
        .then(response => {
          let data = response.data;
          
          this.data = []
          this.total = data.total
          data.data.forEach((item) => {
            this.data.push(item)
          })

        })
        .catch(
          this.data = []
        )
    },
    resetSession (user_id) {
      // let $this = this
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          
          resetSessionUser(user_id, this.$store.state.jwt_user).then(() =>
            {
              EventBus.$emit('successResetSessionUser')
              Toast.open('Запрос отправлен')
            }
          )        

        }
      })
    },
  }
}
</script>
<style>
</style>